var shoppingCartPreview = {
    pageglobals: new PageGlobals(),
    initialize: function () {
        this.getCartData();
        events.listen(events.USER_JUST_LOGGED_IN, shoppingCartPreview, 'onlogin');
        events.listen(events.CART_ITEM_REMOVED, shoppingCartPreview, 'getCartData');
    },
    getCartData: function () {
        var cometRequest = comet.newRequest(cartPreviewGlobals.chandler, 'INIT_PREVIEW', function (cometRequest) {
            growlMessages.showAll(cometRequest);
            var projectPreviewContainer = cometRequest.getWorkingObject().jsonObject;
            shoppingCartPreview.pageglobals.set('projectPreviewContainer', projectPreviewContainer);
            shoppingCartPreview.display();
        });
        cometRequest.send();
    },
    display: function () {
        var projectPreviewContainer = this.pageglobals.projectPreviewContainer;
        var projectCount = projectPreviewContainer.projectCount;
        var content = '';
        if (projectPreviewContainer != null) {
            var jobCount = projectPreviewContainer.cart.jobCount;
            if (jobCount > 0) {
                content += tags.a('<span class="cartCount">' + jobCount + '</span> item' + (jobCount == 1 ? '' : 's'), cartPreviewGlobals.cartURL);
                dynamic.removeClass('cartButton', 'hideIt');
            } else {
                content += '<span>0 Items</span>';
                dynamic.addClass('cartButton', 'hideIt');
                dynamic.addClass('cartContents', 'empty');
            }

            if (projectCount > 0) {
                content += tags.a('<span class="projectCount">' + projectCount + '</span> project' + (projectCount == 1 ? '' : 's'), cartPreviewGlobals.cartURL);
            }
        } else {
            content += '<span>0 Items</span>';
            dynamic.addClass('cartButton', 'hideIt');
            dynamic.addClass('cartContents', 'empty');
        }
        dynamic.setDivIdContent('cartContents', content);
    },
    onlogin: function () {
        this.getCartData();
    },
    checkout: function () {
        gotoURL(cartPreviewGlobals.cartURL);
    }
};

$(function () {
    shoppingCartPreview.initialize();
});
